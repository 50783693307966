import { Checkbox } from 'components/Checkbox';
import { Form } from 'components/Form';
import { Input } from 'components/Input';
import { Popup, PopupProperties } from 'components/Popup';
import { RichText } from 'components/RichText';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styles from './PersonalDataPopup.module.css';
import { useSteps } from 'context/StepsProvider';
import { AllFields } from 'components/helpers/types';
import { captureException } from 'components/helpers/captureException';
import { Select } from 'components/Select';
import { parseFormDataForSubmit } from 'components/helpers/parseFormDataForSubmit';
import { hasCurrentStepErrors } from 'components/helpers/hasCurrentStepErrors';
import { sendIntake } from 'components/helpers/api';
import { deleteWozUuid, getWozResult } from 'components/helpers/cookies';
import { SignatureForm } from './SignatureForm';
import { Button } from 'components/Button';
import { useRouter } from 'next/router';
import { deleteProgress } from 'components/helpers/progress';
import { trackEvent } from 'components/helpers/tracking';

type Properties = Omit<PopupProperties, 'children'>;

type PersonalFields = Partial<AllFields> & { terms: boolean };

export const PersonalDataPopup = ({ onClose, ...rest }: Properties) => {
  const [formError, setFormError] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitDataSuccess, setSubmitDataSuccess] = useState(false);
  const [hasClosedSignatureForm, setHasClosedSignatureForm] = useState(false);
  const [defaultSignatureFormValues, setDefaultSignatureFormValues] = useState<{
    firstName?: string;
    surname?: string;
  }>();

  const { register, formState, watch, trigger, reset } = useFormContext<PersonalFields>();

  const { currentStep } = useSteps();

  const router = useRouter();

  const currentStepFields = useMemo(
    () => currentStep.personalDataPopup?.fields?.map((field) => `personalData.${field.name}` as const).filter(Boolean),
    [currentStep.personalDataPopup?.fields]
  );

  const showSignatureForm = useMemo(() => submitDataSuccess, [submitDataSuccess]);

  const onClosePopup = useCallback(() => {
    if (showSignatureForm) {
      setHasClosedSignatureForm(true);
      return;
    }
    onClose?.();
  }, [showSignatureForm, onClose]);

  const onSubmitData: React.FormEventHandler<HTMLFormElement> = useCallback(
    async (event) => {
      event.preventDefault();

      setFormError(undefined);
      setIsSubmitting(true);

      try {
        await trigger(currentStepFields);

        const errorsArePresent = currentStepFields ? hasCurrentStepErrors(currentStepFields, formState.errors) : false;

        if (errorsArePresent) return;

        if (!watch('terms')) {
          setFormError('Je moet akkoord gaan met de algemene voorwaarden en privacyverklaring');
          return;
        }
        const allData = parseFormDataForSubmit(watch());
        const uuid = getWozResult()?.uuid;

        if (!uuid) throw new Error('No uuid found in intake step');
        if (!allData) throw new Error('No data found in intake step');
        if (!allData.email) throw new Error('No email found in intake step');
        if (!allData.firstName || !allData.surname) throw new Error('No name found in intake step');

        const dataForIntake = {
          salutation: allData.salutation,
          firstName: allData.firstName,
          surname: allData.surname,
          phoneNumber: allData.phoneNumber,
          email: allData.email || '',
        };

        setDefaultSignatureFormValues({
          firstName: allData.firstName,
          surname: allData.surname,
        });

        await sendIntake(uuid, dataForIntake);

        trackEvent('submitIntake');

        setSubmitDataSuccess(true);
        reset();
      } catch (_error) {
        captureException(_error, { tags: { fn: 'PersonalDataPopup.onSubmit' } });
        setFormError('Er is iets misgegaan, probeer het later opnieuw.');
      } finally {
        setIsSubmitting(false);
      }
    },
    [trigger, currentStepFields, formState.errors, watch, reset]
  );

  const onCloseWithoutSignature = useCallback(() => {
    deleteProgress();
    deleteWozUuid();
    router.replace('https://juistwoz.nl/');
  }, [router]);

  return (
    <Popup onClose={hasClosedSignatureForm ? undefined : onClosePopup} {...rest}>
      {hasClosedSignatureForm ? (
        <Fragment>
          <RichText>
            <h2 className={styles.title}>Weet je zeker dat je de popup wilt sluiten?</h2>
            <p>Je kan je handtekening ook op een later moment zetten</p>
          </RichText>
          <div className={styles.buttonContainer}>
            <Button onClick={() => setHasClosedSignatureForm(false)}>Nee, ik wil nu ondertekenen</Button>
            <Button theme="secondary" onClick={onCloseWithoutSignature}>
              Ja, sluit de woz-scan
            </Button>
          </div>
        </Fragment>
      ) : showSignatureForm && defaultSignatureFormValues ? (
        <SignatureForm defaultValuesFromSubmit={defaultSignatureFormValues} />
      ) : (
        <div className={styles.formContainer}>
          <Form isSubmitting={isSubmitting} onSubmit={onSubmitData} error={formError} buttonLabel="Bezwaar indienen">
            <RichText>
              <h2 className={styles.title}>{currentStep.personalDataPopup?.title}</h2>
              <p>{currentStep.personalDataPopup?.description}</p>
            </RichText>
            {currentStep?.personalDataPopup?.fields?.map((field) => {
              if (!field.name) return;
              if (field.type === 'select') {
                return (
                  <Select
                    required={field.required}
                    key={field.label}
                    error={formState?.errors?.['personalData']?.[field.name]?.message?.toString()}
                    description={field.label}
                    options={field.options}
                    {...register(`personalData.${field.name}` as const)}
                  />
                );
              }
              return (
                <Input
                  required={field.required}
                  key={field.label}
                  type={field.type}
                  error={formState?.errors?.['personalData']?.[field.name]?.message?.toString()}
                  description={field.label}
                  placeholder={field.placeholder}
                  {...register(`personalData.${field.name}` as const)}
                />
              );
            })}
            <Checkbox
              error={formState?.errors?.terms?.message?.toString()}
              label="Hierbij ga ik akkoord dat JUIST namens mij bezwaar maakt bij de gemeente om de WOZ-waarde te controleren en zo mogelijk te verlagen"
              {...register('terms')}
            />
            <p className={styles.bottomText}>
              Op de volgende pagina kun je de machtiging tekenen. Deze hebben we nodig om jouw bezwaar af te handelen.
            </p>
          </Form>
          <RichText>
            <p>
              Onze werkzaamheden worden <strong>100% vergoed</strong> door de gemeente
            </p>
          </RichText>
        </div>
      )}
    </Popup>
  );
};
