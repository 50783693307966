import styles from './Stepper.module.css';
import { StepperSidebar } from './StepperSidebar';
import { StepContainer } from './StepContainer';
import { FormProvider } from 'react-hook-form';
import { PersonalDataPopup } from 'components/PersonalDataPopup';
import { useSteps } from 'context/StepsProvider';
import { Popup } from 'components/Popup';
import { RichText } from 'components/RichText';
import { ButtonLink } from 'components/Button';
import { VerticalMargin } from 'components/VerticalMargin';

export const Stepper = () => {
  const {
    currentStep,
    CurrentSubStep,
    currentStepIndex,
    currentSubStepIndex,
    hasPreviousStep,
    hasNextStep,
    popup,
    isNextStepDisabled,
    formMethods,
    handleGoToStep,
    handlePreviousStep,
    handleNextStep,
  } = useSteps();

  return (
    <FormProvider {...formMethods}>
      <div className={styles.container}>
        <StepperSidebar goToStep={handleGoToStep} currentStepIndex={currentStepIndex} />
        <StepContainer
          isNextButtonDisabled={isNextStepDisabled}
          currentStep={currentStep}
          currentStepIndex={currentStepIndex}
          currentSubStepIndex={currentSubStepIndex}
          hasPreviousStep={hasPreviousStep}
          goToPreviousStep={handlePreviousStep}
          goToNextStep={handleNextStep}
          hasNextStep={hasNextStep}>
          <CurrentSubStep />
        </StepContainer>
        {popup?.type === 'personalData' ? (
          <PersonalDataPopup onClose={popup?.onClose} />
        ) : popup?.type === 'popup' ? (
          <Popup onClose={popup?.onClose}>
            <RichText>
              <h2 className={styles.title}>{currentStep.personalDataPopup?.title}</h2>
              <p>{currentStep.personalDataPopup?.description}</p>
            </RichText>
            <VerticalMargin size="small" />
            <ButtonLink theme="primary" icon="ArrowRightIcon" href="https://juistwoz.nl/">
              Terug naar de homepage
            </ButtonLink>
          </Popup>
        ) : undefined}
      </div>
    </FormProvider>
  );
};
