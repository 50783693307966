import React from 'react';
import { Layout } from 'components/Layout';
import { Stepper } from 'components/Stepper';

const HomePage = () => (
  <Layout>
    <Stepper />
  </Layout>
);

export default HomePage;
