import { Fragment, MouseEventHandler, useCallback, useMemo } from 'react';
import styles from './StepperSidebar.module.css';
import { SidebarHeader } from './SidebarHeader';
import { SidebarFooter } from './SidebarFooter';
import cx from 'classnames';
import { HeroIcon } from 'components/HeroIcon';
import { Step } from 'components/helpers/types';
import { useIsMobile } from 'hooks/useIsMobile';
import { scrollToStep } from './helpers/scrollToStep';
import { STEPS } from 'components/constants/STEPS';
import { PrevicusIcon } from 'components/PrevicusIcon';

export interface StepperSidebarProperties {
  currentStepIndex: number;
  goToStep: (index: number) => void;
}

export const StepperSidebar = ({ currentStepIndex, goToStep }: StepperSidebarProperties) => {
  const onSelectStep: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      const newStepIndex = Number.parseInt(event.currentTarget.dataset.index || '0', 10);
      goToStep(newStepIndex);
      scrollToStep(newStepIndex);
    },
    [goToStep]
  );

  const isMobile = useIsMobile();

  return (
    //do not put an element around this, it will break the 'stickyness' of the sidebar steps
    <Fragment>
      {!!isMobile && <SidebarHeader />}
      <div className={cx(styles.stepListContainer, { [styles.beforeHidden]: currentStepIndex === 0 })}>
        <ul className={styles.stepList}>
          {!isMobile && <SidebarHeader />}
          {STEPS.map((step, index) => {
            const stepStatus = index > currentStepIndex ? 'todo' : index === currentStepIndex ? 'active' : 'done';
            const isDisabled = index > currentStepIndex;
            return (
              <Fragment key={step.title}>
                <li key={step.title}>
                  <SidebarStep
                    onSelectStep={onSelectStep}
                    status={stepStatus}
                    index={index}
                    disabled={isDisabled}
                    {...step}
                  />
                </li>
                {index < STEPS.length - 1 ? <span /> : undefined}
              </Fragment>
            );
          })}
        </ul>
        {!isMobile && <SidebarFooter />}
      </div>
      {!!isMobile && <SidebarFooter />}
    </Fragment>
  );
};

interface StepProperties extends Omit<Step, 'subTitle'> {
  index: number;
  onSelectStep: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

const SidebarStep = ({ title, index, onSelectStep, status, icon, disabled = false }: StepProperties) => {
  const IconToUse = useMemo(() => {
    if (status === 'done') {
      return <HeroIcon icon="CheckCircleIcon" />;
    }

    return icon == 'PrevicusIcon' ? <PrevicusIcon disabled={status !== 'active'} /> : <HeroIcon icon={icon} />;
  }, [icon, status]);
  return (
    <button
      type="button"
      data-index={index}
      onClick={onSelectStep}
      disabled={disabled}
      className={cx(styles.step, { [styles.active]: status === 'active', [styles.disabled]: disabled })}>
      <div
        className={cx(styles.iconContainer, {
          [styles.active]: status === 'active',
        })}>
        {IconToUse}
      </div>
      <div className={styles.textContainer}>
        <h2
          className={cx(styles.stepTitle, {
            [styles.active]: status === 'active',
          })}>
          {title}
        </h2>
        <div
          className={cx(styles.stepBar, {
            [styles.active]: status === 'active',
          })}
        />
      </div>
    </button>
  );
};
