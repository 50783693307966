import classNames from 'classnames';
import { ReactNode, useMemo } from 'react';
import styles from './MaxWidth.module.css';

interface Properties {
  children: ReactNode;
  size?: 'small' | 'medium' | 'large';
  className?: string;
}

export const MaxWidth = ({ children, size = 'large', className }: Properties): JSX.Element => {
  const containerClassName = useMemo(() => classNames(styles.container, styles[size], className), [className, size]);

  return <div className={containerClassName}>{children}</div>;
};
