import React from 'react';

export const Logo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 150 48">
    <g clipPath="url(#clip0_967_26408)">
      <path
        fill="#FC9833"
        d="M22.2 45.1a22.4 22.4 0 0 0 22.2-22.5C44.4 10 34.4 0 22.2 0A22.4 22.4 0 0 0 0 22.6C0 35 10 45 22.2 45Z"
      />
      <path
        fill="white"
        d="M32.5 35.9H11.9c-1.2 0-2.2-1-2.2-2.3V17a2.8 2.8 0 0 1 1-2.1l9.7-8.4a2.7 2.7 0 0 1 3.6 0l9.5 8.2a2.8 2.8 0 0 1 0 4.2L24 27.1a2.7 2.7 0 0 1-3.6 0L17 24a1 1 0 0 1 0-1.3l1.6-2a1 1 0 0 1 1.3-.2l2.3 2 6.8-5.8-6.8-5.8-8 6.9v13.4h16v-4.6c0-.6.4-1 1-1h2.6c.5 0 .9.4.9 1v7c0 1.2-1 2.2-2.2 2.2ZM21 10Z"
      />
      <path
        fill="white"
        d="M69.2 44.4v-4h.9v4c0 .5-.2.8-.5 1.1a1.6 1.6 0 0 1-2.3 0c-.3-.3-.4-.6-.4-1.1h.9l.1.5c.2.2.3.3.6.3.2 0 .4-.1.5-.3l.2-.5Z"
      />
      <path
        fill="white"
        d="M73.5 46a2 2 0 0 1-1.5-.6c-.4-.5-.7-1-.7-1.7s.3-1.2.7-1.6a2 2 0 0 1 1.6-.7 2.1 2.1 0 0 1 2.2 2.3 2.2 2.2 0 0 1-2.3 2.3Zm1-1.2c.2-.3.3-.6.3-1 0-.6 0-1-.3-1.2-.3-.3-.6-.4-1-.4-.3 0-.6.1-.9.4-.2.3-.3.6-.3 1.1 0 .5 0 .9.3 1.1.3.3.6.4 1 .4.3 0 .6-.1.8-.4Z"
      />
      <path
        fill="white"
        d="M79.8 41.5h.9V46h-1v-.7c-.2.5-.7.8-1.3.8-.5 0-.9-.2-1.2-.5a2 2 0 0 1-.5-1.4v-2.6h1V44c0 .4 0 .7.2.9.2.2.5.3.8.3.3 0 .6-.1.8-.3.2-.2.3-.5.3-1v-2.4Z"
      />
      <path fill="white" d="M86.9 41.5h.9L86.4 46h-.9l-.9-3.2-.8 3.2h-1l-1.3-4.4h.9l.9 3.5.9-3.5h1L86 45l.9-3.5Z" />
      <path
        fill="white"
        d="M97.3 40.4h1l-1.6 5.5h-1l-1.2-4.2-1.2 4.2h-1l-1.5-5.5h.9l1.1 4.5 1.3-4.5h1l1.1 4.5 1.1-4.5Z"
      />
      <path
        fill="white"
        d="M101.6 46c-.7 0-1.4-.3-2-.8-.5-.6-.7-1.2-.7-2a2.7 2.7 0 0 1 2.7-3c.8 0 1.5.4 2 .9.6.5.8 1.2.8 2a2.8 2.8 0 0 1-2.8 2.9Zm0-.9c.6 0 1-.1 1.4-.5.3-.4.5-.9.5-1.5s-.2-1-.5-1.4c-.4-.4-.8-.6-1.4-.6-.5 0-1 .2-1.3.6a2 2 0 0 0-.5 1.4c0 .6.1 1.1.5 1.5.3.4.8.5 1.3.5Z"
      />
      <path fill="white" d="M108.8 40.4v.7l-2.5 4.1h2.5v.7h-3.6v-.7l2.6-4.1h-2.6v-.7h3.6Z" />
      <path fill="white" d="M113.1 46h-.9v-6h1v3.4l1.5-1.9h1.2l-2 2.2 2 2.2h-1.2l-1.6-1.9v2Z" />
      <path fill="white" d="M116.7 46v-6h.9v6h-.9Z" />
      <path
        fill="white"
        d="M120.8 46a2 2 0 0 1-1.6-.6c-.4-.5-.6-1-.6-1.7s.2-1.2.6-1.6c.5-.5 1-.7 1.6-.7a2.1 2.1 0 0 1 2.2 2.3 2.2 2.2 0 0 1-2.2 2.3Zm.9-1.2c.3-.3.4-.6.4-1 0-.6-.1-1-.4-1.2-.2-.3-.5-.4-1-.4a1 1 0 0 0-.8.4c-.3.3-.4.6-.4 1.1 0 .5.1.9.4 1.1.2.3.5.4.9.4.3 0 .6-.1.9-.4Z"
      />
      <path
        fill="white"
        d="M126.4 41.4c.6 0 1 .3 1.5.7.4.4.6 1 .6 1.6 0 .7-.2 1.2-.6 1.6a1.9 1.9 0 0 1-2.4.4l-.6-.6V48h-.9v-6.5h1v.8a1.7 1.7 0 0 1 1.4-.9Zm.7 3.4c.3-.3.4-.7.4-1.1 0-.5 0-.8-.3-1-.3-.3-.6-.5-1-.5-.3 0-.6.2-.9.4-.3.3-.4.7-.4 1.1 0 .5.1.8.4 1 .3.4.6.5 1 .5.3 0 .6-.1.8-.4Z"
      />
      <path
        fill="white"
        d="M131.6 42.3h-1.1v2.4l.1.3.4.2h.6v.7h-.8c-.8 0-1.2-.4-1.2-1.2v-2.4h-.5v-.8h.5v-1h.9v1h1v.8Z"
      />
      <path
        fill="white"
        d="M93.6 11.3H89a1 1 0 0 0-.9 1v13.9a5 5 0 0 1-1 3.2c-.7.8-1.7 1.2-3 1.2-1.5 0-2.5-.4-3.2-1.2a5 5 0 0 1-1-3.2v-14c0-.4-.4-.9-1-.9h-4.4a1 1 0 0 0-1 1v14c0 1.6.3 3 .8 4.3a8.5 8.5 0 0 0 5.4 5c1.3.4 2.7.7 4.3.7 1.6 0 3-.3 4.3-.7 1.2-.4 2.4-1 3.3-2 1-.7 1.6-1.8 2.1-3 .5-1.3.8-2.7.8-4.3v-14c0-.5-.4-1-1-1Z"
      />
      <path fill="white" d="M104 11.3h-4.6a1 1 0 0 0-1 1V35c0 .5.5.9 1 .9h4.5c.5 0 1-.4 1-1V12.4c0-.5-.5-1-1-1Z" />
      <path
        fill="white"
        d="M127 23.6a11 11 0 0 0-2.4-1.5l-3.3-1a198.9 198.9 0 0 1-5.2-1.2 3 3 0 0 1-1.1-.6c-.2-.3-.4-.6-.4-1 0-.3.1-.5.3-.8l.8-.5c.3-.2.7-.2 1-.3l1.3-.1c.6 0 1.1 0 1.5.2.5.1.8.3 1 .5.4.1.6.3.7.5a15.2 15.2 0 0 0 .4.3l.6.4.7.2h4.5c.2 0 .4-.1.5-.3.2-.1.3-.3.3-.5 0-.7-.3-1.5-.8-2.3a8.4 8.4 0 0 0-2.1-2.3A11.7 11.7 0 0 0 118 11c-1.4 0-2.8.2-4 .6a9.2 9.2 0 0 0-5.1 4.1c-.4.9-.6 1.9-.6 2.9 0 2 .5 3.6 1.6 4.8 1 1.2 2.8 2 5.2 2.6a165.3 165.3 0 0 0 5.6 1.3c.6.1 1 .4 1.3.6.3.2.5.5.5.9 0 .7-.4 1.2-1.2 1.4a9.9 9.9 0 0 1-4.8.2 4.6 4.6 0 0 1-2.3-1.7h-.1l-.5-.4a2 2 0 0 0-.8 0h-4.5l-.5.1c-.2.2-.2.4-.2.6 0 .9.3 1.8.8 2.6.5.9 1.3 1.7 2.2 2.4 1 .6 2 1.2 3.3 1.6a16.2 16.2 0 0 0 8.5.1c1.4-.3 2.5-.8 3.4-1.5 1-.7 1.7-1.5 2.3-2.4.5-1 .8-2 .8-3.2a8 8 0 0 0-.5-2.9c-.3-.8-.8-1.5-1.4-2Z"
      />
      <path
        fill="white"
        d="M129.6 12.3V16c0 .5.4 1 1 1h6v18c0 .5.4.9 1 .9h4.5c.5 0 .9-.4.9-1V17h6c.6 0 1-.4 1-.9v-3.7c0-.5-.4-1-1-1h-18.5a1 1 0 0 0-.9 1Z"
      />
      <path
        fill="white"
        d="M52 27.2h3c.3 0 .7.2.8.6l.2.6c.3.7.7 1.2 1.3 1.6.5.4 1.4.6 2.5.6 1.3 0 2.3-.3 3-1a4 4 0 0 0 1-2.9V17h-6.5a1 1 0 0 1-1-1v-3.7c0-.5.5-1 1-1h11.8c.6 0 1 .5 1 1V27c0 1.5-.3 2.9-.9 4a8.5 8.5 0 0 1-2.2 3c-1 .7-2 1.3-3.3 1.7a13.4 13.4 0 0 1-8 0c-1.3-.4-2.3-1-3.2-1.7a7.7 7.7 0 0 1-3-5.9c0-.6.4-.9 1-.9h1.4"
      />
    </g>
    <defs>
      <clipPath id="clip0_967_26408">
        <rect width="150" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
