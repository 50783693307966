import { PHONE_NUMBER_WIHTOUT_DASHES, PHONE_NUMBER } from 'components/constants/PHONE_NUMBER';
import styles from './SidebarFooter.module.css';
import { ButtonLink } from 'components/Button';

export const SidebarFooter = () => (
  <footer className={styles.footer}>
    <p>© Juist {new Date().getFullYear()}</p>
    <div className={styles.contactContainer}>
      <ButtonLink iconLeft="EnvelopeOpenIcon" theme="plain" href={`mailto:info@juistwoz.nl `}>
        info@juistwoz.nl
      </ButtonLink>
      <ButtonLink iconLeft="PhoneIcon" theme="plain" href={`tel:${PHONE_NUMBER_WIHTOUT_DASHES}`}>
        {PHONE_NUMBER}
      </ButtonLink>
    </div>
  </footer>
);
