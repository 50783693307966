import React from 'react';

export const PrevicusIcon = ({ disabled = false }: { disabled?: boolean }) => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="500 420 1200 1200">
    <path
      fill={disabled ? '#20A9A4' : '#fcfbfb'}
      fillRule="evenodd"
      stroke="none"
      d="M1033.5 362.6a119.8 119.8 0 0 0-58.4 21.5c-14.5 11-414.5 349.5-420 355.5a115.4 115.4 0 0 0-25.4 44.4c-5 17-4.7-2.2-4.7 369.4 0 333.6 0 346.4 1.9 355.1 4 19.6 13 35.5 27.9 49.6a92 92 0 0 0 44 23.6c6.2 1.6 36.8 1.7 435.2 2 304.7.3 431.6 0 439.3-.7a93.1 93.1 0 0 0 81.4-66.7l2.8-8.8v-310l-3.3-6.7a41 41 0 0 0-20-19l-5.7-2.3h-127l-6.7 3.2c-8.5 4-15 10.6-19.1 19.1l-3.2 6.7-.3 99.3-.2 99.2H711V850.2l156.2-131.8 165.4-139.6 9-7.6 139.2 117.4c76.6 64.6 139.2 117.8 139.2 118.2 0 1.2-277.2 234.7-278.5 234.6-.7 0-22.5-18-48.5-40a1785 1785 0 0 0-50.8-42.1 39.3 39.3 0 0 0-43.1 3.3c-3.9 3.4-68.6 79.2-77.3 90.7a43.6 43.6 0 0 0-7.8 22.6 39 39 0 0 0 12.7 28.3c6.2 6 139.7 118.6 148.3 125.2 8.6 6.6 26.1 14.9 38.5 18.2a80 80 0 0 0 28 2.8 82 82 0 0 0 27.7-2.6c12.7-3.5 23.8-8.4 34-15 12.1-8 400-335.5 412.3-348.2a109.8 109.8 0 0 0 28.8-51.6c2.9-10.5 3-38.7.4-50.5-4.1-17.7-12.7-35-24.3-48.8-4.8-5.7-55.5-49-208.2-177.9-111-93.7-204.5-172.2-208-174.5a115 115 0 0 0-70.7-18.7"
    />
    <path
      fill={disabled ? '#3AC7BF' : '#fc9c34'}
      fillRule="evenodd"
      stroke="none"
      d="M1004.5 124.7a914.6 914.6 0 0 0-673 335.8 924.5 924.5 0 0 0-161 292 925.4 925.4 0 0 0-12.5 537.6 918.8 918.8 0 0 0 194 356.8c16.5 18.9 62.9 65.7 80.1 80.7A915.4 915.4 0 0 0 975 1957a1368 1368 0 0 0 113.5 1 909 909 0 0 0 461-152.1 917.9 917.9 0 0 0 387.8-563.9 890 890 0 0 0 21.4-214c-.6-40.8-1.4-54.6-5.2-89a910.8 910.8 0 0 0-177.1-447c-29.3-39-49.4-62-85.9-98.6a856.7 856.7 0 0 0-140-115.3 913.4 913.4 0 0 0-546-153.4m29 237.9a119.8 119.8 0 0 0-58.4 21.5c-14.5 11-414.5 349.5-420 355.5a115.4 115.4 0 0 0-25.4 44.4c-5 17-4.7-2.2-4.7 369.4 0 333.6 0 346.4 1.9 355.1 4 19.6 13 35.5 27.9 49.6a92 92 0 0 0 44 23.6c6.2 1.6 36.8 1.7 435.2 2 304.7.3 431.6 0 439.3-.7a93.1 93.1 0 0 0 81.4-66.7l2.8-8.8v-310l-3.3-6.7a41 41 0 0 0-20-19l-5.7-2.3h-127l-6.7 3.2c-8.5 4-15 10.6-19.1 19.1l-3.2 6.7-.3 99.3-.2 99.2H711V850.2l156.2-131.8 165.4-139.6 9-7.6 139.2 117.4c76.6 64.6 139.2 117.8 139.2 118.2 0 1.2-277.2 234.7-278.5 234.6-.7 0-22.5-18-48.5-40a1785 1785 0 0 0-50.8-42.1 39.3 39.3 0 0 0-43.1 3.3c-3.9 3.4-68.6 79.2-77.3 90.7a43.6 43.6 0 0 0-7.8 22.6 39 39 0 0 0 12.7 28.3c6.2 6 139.7 118.6 148.3 125.2 8.6 6.6 26.1 14.9 38.5 18.2a80 80 0 0 0 28 2.8 82 82 0 0 0 27.7-2.6c12.7-3.5 23.8-8.4 34-15 12.1-8 400-335.5 412.3-348.2a109.8 109.8 0 0 0 28.8-51.6c2.9-10.5 3-38.7.4-50.5-4.1-17.7-12.7-35-24.3-48.8-4.8-5.7-55.5-49-208.2-177.9-111-93.7-204.5-172.2-208-174.5a115 115 0 0 0-70.7-18.7"
    />
  </svg>
);
