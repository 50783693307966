import { AnimatePresence, motion } from 'framer-motion';
import { Button } from './Button';
import styles from './StepContainer.module.css';
import { RichText } from 'components/RichText';
import { Fragment, ReactNode } from 'react';
import { HeroIcon } from 'components/HeroIcon';
import { Step } from 'components/helpers/types';
import { useSteps } from 'context/StepsProvider';
import { STEPS } from 'components/constants/STEPS';
import { PrevicusIcon } from 'components/PrevicusIcon';
import cx from 'classnames';

interface StepContainerProperties {
  currentStep: Step;
  currentStepIndex: number;
  children: ReactNode;
  currentSubStepIndex: number;
  hasPreviousStep: boolean;
  goToPreviousStep: () => void;
  goToNextStep: () => void;
  hasNextStep: boolean;
  isNextButtonDisabled?: boolean;
}

export const StepContainer = ({
  children,
  currentSubStepIndex,
  currentStep,
  currentStepIndex,
  hasPreviousStep,
  goToPreviousStep,
  goToNextStep,
  hasNextStep,
  isNextButtonDisabled,
}: StepContainerProperties) => {
  const { hideNextButton, hidePreviousButton } = useSteps();

  return (
    <div className={cx(styles.stepContainer, { [styles.result]: currentStepIndex === STEPS.length - 1 })}>
      <AnimatePresence mode="wait">
        <motion.div
          key={`${currentStepIndex}-${currentSubStepIndex}`}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -10, opacity: 0 }}
          transition={{ duration: 0.2 }}>
          <RichText center>
            <div className={styles.iconContainer}>
              <div className={styles.icon}>
                {currentStep.icon === 'PrevicusIcon' ? <PrevicusIcon /> : <HeroIcon icon={currentStep.icon} />}
              </div>
            </div>
            {currentStepIndex !== STEPS.length - 1 && (
              <Fragment>
                <h1>{currentStep.componentTitle || currentStep.title}</h1>
                <div className={styles.subTitle}>
                  <p>{currentStep.subTitle}</p>
                </div>
              </Fragment>
            )}
          </RichText>
          <div className={styles.childrensContainer}>{children}</div>
        </motion.div>
      </AnimatePresence>
      <div className={styles.buttonContainer}>
        {!hidePreviousButton && (
          <Button
            iconLeft="ArrowLeftIcon"
            onClick={goToPreviousStep}
            disabled={!hasPreviousStep}
            theme={hasPreviousStep ? 'secondary' : undefined}>
            Vorige
          </Button>
        )}
        {!hideNextButton && (
          <Button
            icon={hasNextStep ? 'ArrowRightIcon' : 'CheckIcon'}
            disabled={isNextButtonDisabled}
            onClick={goToNextStep}>
            Volgende
          </Button>
        )}
      </div>
    </div>
  );
};
