import { ButtonLink } from 'components/Button';
import styles from './SidebarHeader.module.css';
import { Logo } from 'components/Logo';
import { PHONE_NUMBER_WIHTOUT_DASHES } from 'components/constants/PHONE_NUMBER';

export const SidebarHeader = () => (
  <header className={styles.header}>
    <Logo />

    <ButtonLink iconLeft="PhoneIcon" theme="secondary" href={`tel:${PHONE_NUMBER_WIHTOUT_DASHES}`}>
      Hulp nodig?
    </ButtonLink>
  </header>
);
