import { MaxWidth } from 'components/MaxWidth';
import React, { ReactNode } from 'react';
import styles from './Layout.module.css';

interface Properties {
  children: ReactNode;
}

export const Layout = ({ children }: Properties) => (
  <MaxWidth>
    <div className={styles.layout}>{children}</div>
  </MaxWidth>
);
